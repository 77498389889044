





















import {Vue, Component} from "vue-property-decorator";
import IssueFriendType from "@/views/Friend/IssueFriend/IssueFriend";
import IssueFriendContent from "@/views/Friend/IssueFriend/components/Content/index.vue";
import IssueFriendFooter from "@/views/Friend/IssueFriend/components/Footer/index.vue";
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import {IssueFriendStore} from "@/views/Friend/IssueFriend/Store";
import {IssueFriendCircle} from "@/views/Friend/IssueFriend/Server";
import ZoomPage from "@/impView/PageSafety";

@Component({name: "IssueFriend",components:{ IssueFriendContent,IssueFriendFooter,HeadTop }})
export default class IssueFriend extends ZoomPage implements IssueFriendType{
    loadingShow = true;

    mounted(){
        this.loadingShow = false
    }

    handleBack(){
        IssueFriendStore.clearIssueFriend()
        this.$router.go(-1)
    }

    handleUpFriends(){
        IssueFriendCircle().then(res=>{
            let time = setTimeout(()=>{
                this.$router.go(-1)
                clearTimeout(time)
            },600)
        })
    }
}
