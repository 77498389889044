
























import {Vue, Component, Watch} from "vue-property-decorator";
import IssueFriendContentType from "@/views/Friend/IssueFriend/components/Content/indexType";
import {Loading, Toast} from "vant";
import {upPhotos} from "@/views/Friend/IssueFriend/Server";
import {IssueFriendStore} from "@/views/Friend/IssueFriend/Store";

@Component({name: "IssueFriendContent"})
export default class IssueFriendContent extends Vue implements IssueFriendContentType{
    UpIcon = require("../../../Img/add.png");
    fileList: any[] = [];
    imgList:string[] = []
    message = ""

    handleAfterRead(e: any): void {
        if ( e.length <= 0 ){
            Toast("请选择上传图片")
            return;
        }
        if ( this.fileList.length ){
            Toast.loading({ duration: 0,forbidClick: true,message: '上传中~', })
            upPhotos(this.fileList).then(res=>{
                Toast.clear()
                this.imgList = res
            })
        }else{
            Toast("请选择上传图片")
            return;
        }
    }

    handleRemoveList(e: any, keyData: any): void {
        let { index } = keyData
        try{
            this.fileList.splice( index,1 )
            this.imgList.splice(index,1)
        }catch (e) {
            console.info("删除图片失败")
        }
    }

    @Watch("imgList",{ deep:true })
    changeImgList(newVal:string[]){
        IssueFriendStore.setIssueImgList( newVal )
    }
    @Watch("message")
    changeMessage(newVal:string){
        IssueFriendStore.setIssueMessage( newVal )
    }
}
