




































import {Vue, Component} from "vue-property-decorator";
import IssueFriendFooterType from "@/views/Friend/IssueFriend/components/Footer/indexType";
import {IssueFriendStore} from "@/views/Friend/IssueFriend/Store";
import {IssueFriendAdmin} from "@/views/Friend/IssueFriend/Store/indexType";

@Component({name: "IssueFriendFooter"})
export default class IssueFriendFooter extends Vue implements IssueFriendFooterType{
    AddressIcon = require("../../../Img/FirendAddress.png");
    ExamineIcon = require("../../../Img/FirendUser.png");
    RightIcon = require("@/assets/icon/Basics/Right.png");
    friendAdmin: IssueFriendAdmin = {
        value:"",
        type:0
    };

    mounted(){
        this.friendAdmin = IssueFriendStore.getIssueAdmin
    }

    handleToPath(state: number): void {
        let strPath = ""
        if ( state ){
            strPath = "/friendExamine"
        }else{
            strPath = "/friendAddress?state=friend"
        }
        this.$router.push( {
            path:strPath
        } )
    }

    get getAddressData(){
        let addressData = IssueFriendStore.getIssueAddress
        if ( addressData.state == "ok" ){
            if ( addressData.showSiteStr ){
                return addressData.showSiteStr
            }else if ( addressData.address ){
                return addressData.address
            }else {
                return `${ addressData.city }${ addressData.district }${ addressData.township }`
            }
        }else{
            return "请选择您的位置信息"
        }
    }
}
